import $ from 'jquery';

import Utils from '../global/Utils';

export default class ArticlePush  {
  constructor(o) {
    this.obj = $(o);
    this.el = $(o).get();
    this.addListeners();
    this.init();
  }

  init() {
    var self = this;
    var block = this.obj.find('a.image');
    var line = this.obj.find('hr');
    var holder = this.obj.find('.holder');

    // console.log(block.height());
    // console.log(block.offset().top);
    // console.log(line.offset().top);
    
    $(document).on('windowResize', function(e, vals) {
      if (vals.device === 'desktop') {
        holder.css({ top: block.height() - (block.offset().top - line.offset().top) * -1 });     
      }
    });
  }

  addListeners() {
    /*this.obj.find('li').on('click', e => {
      window.location.href = '#' + $(e.currentTarget).attr('data-product');
    });*/

  }
}