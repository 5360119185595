import $ from 'jquery';

export default class Api	{
	constructor(request) {
		this.url = `${window.siteRoot}/${request}`;
	}
	/*load(el) {
		return new Promise((resolve, reject) => {
			$('#hidden').remove();
			$('body').append('<div id="hidden" />');
			$('#hidden').load(`${this.url} ${el}`, resolve);
		});
	}

	makeRequest(request)	{
		return new Promise((resolve, reject) => {
			$.ajax({
				url: `${window.siteRoot}api/${request}`,
				type: 'GET',
				dataType: 'json',
				success: (result) => {
					resolve(result);
				}
			});
		});
	}*/
	static googleApi(path, search) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: `https://maps.googleapis.com/maps/api/place/${path}/json?key=AIzaSyATz5jD0_Io8v39ArJQ6Shzp6UE_tCxDrI&${search}`,
				type: 'GET',
				dataType: 'json',
				success: (result) => {
					resolve(result);
				}
			});
		});
	}
	static postInternal(path, data) {
		// console.log('posting', data, path);
		return new Promise((resolve, reject) => {
			$.ajax({
				url: `${path}`,
				type: 'POST',
				data: {
					data: JSON.stringify(data)
				},
				dataType: 'json',
				success: (result) => {
					resolve(result);
				}
			});
		});
	}

	static getStoreFinderPath() {
		const site = window.site.split('/');
		let lang = window.sitePath.split('/');

		//works for the main site where no language is specified
		if(lang[1] === 'store-finder') {
			lang[1] = '';
		}

		return `${window.sitePath}/${site[0]}/brunost-stores.json?site=${site[0]}&country=${lang[1]}`;
	}

	static getSites() {
		const site = window.site.split('/');
		const lang = window.sitePath.split('/');
		return `${window.sitePath}/${site[0]}/sites.json?site=${site[0]}&country=${lang[1]}`;
	}
}
