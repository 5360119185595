import $ from 'jquery';

import Utils from '../global/Utils';

import fitvid from 'fitvids';

export default class ArticlePush  {
  constructor(o) {
    this.obj = $(o);
    this.el = $(o).get();
    this.addListeners();
    this.init();
  }

  init() {
    fitvid();
  }

  addListeners() {
    /*this.obj.find('li').on('click', e => {
      window.location.href = '#' + $(e.currentTarget).attr('data-product');
    });*/

  }
}