import $ from 'jquery';
import Hammer from 'hammerjs';
import Utils from './Utils';
import TweenMax from 'gsap';
import Linear from 'gsap';

export default class Slider {

  constructor(runThis = false, itemsPerSlide = 1) {
    this.initSliders(runThis, itemsPerSlide);
  }

  slide(obj, nextslide, cachedslide, direction = false) {
    var marginleft,
        self = this;
    marginleft = nextslide * (100 / obj.itemsPerSlide);

    // obj.self.find('.stripe').animate({
    //   marginLeft: '-' + marginleft + '%'
    // }, 2000);

    TweenMax.to(obj.self.find('.stripe'), .5, 
      { marginLeft: '-' + marginleft + '%', ease: Linear.easeNone }
    );

    obj.nav.find('a').removeClass('active');
    obj.nav.find('a:eq(' + nextslide + ')').addClass('active');

    obj.self.data('currentslide', nextslide);

    obj.self.find('.stripe > div a').removeClass('previous current next next-xt previous-us');
    obj.self.find('.stripe > div:eq(' + (nextslide - 1) + ') a').addClass('previous-us');
    obj.self.find('.stripe > div:eq(' + nextslide + ') a').addClass('previous');
    obj.self.find('.stripe > div:eq(' + (nextslide + 1) + ') a').addClass('current');
    obj.self.find('.stripe > div:eq(' + (nextslide + 2) + ') a').addClass('next');
    obj.self.find('.stripe > div:eq(' + (nextslide + 3) + ') a').addClass('next-xt');

    if ((nextslide + 1) + (obj.itemsPerSlide - 1) >= obj.self.find('.stripe.crow .slide').length) {
      obj.nextbutton.addClass('inactive');
    } else {
      obj.nextbutton.removeClass('inactive');
    }

    if ((nextslide - 1) < 0) {
      obj.prevbutton.addClass('inactive');
    } else {
      obj.prevbutton.removeClass('inactive');
    }

    // if ((nextslide + 1) === obj.self.find('.slide').length) {
    //   obj.prevbutton.removeClass('inactive');
    //   //return 0;
    // } else {
    //   obj.nextbutton.removeClass('inactive');
    //   if (nextslide !== 0) {
    //     obj.prevbutton.removeClass('inactive');
    //   }
    //   return nextslide;
    // }
    if (obj.self.find('.background-image').length) {
      self.animateBackground(obj, nextslide, direction);
    }
  }

  slideTo(direction, slider, interv = false) {
    var cachedslide, self;
    var currentslide = slider.self.data('currentslide');

    self = this;

    cachedslide = currentslide;
    if (!interv) {
      clearInterval(slider.self.data('interval'));
    }
    if (direction === 'next') {
      currentslide++;
      if (currentslide + (slider.itemsPerSlide - 1) >= slider.self.find('.stripe.crow .slide').length) {
        currentslide = 0;
      }
      self.slide(slider, currentslide, cachedslide, direction);
    } else if (direction === 'prev') {
      currentslide--;
      if (currentslide < 0) {
        let rest = slider.self.find('.stripe.crow .slide').length % slider.itemsPerSlide;
        rest = slider.itemsPerSlide - rest;
        currentslide = slider.self.find('.stripe.crow .slide').length - 1 - rest;
      }
      self.slide(slider, currentslide, cachedslide, direction);
    }
  }

  animateBackground(obj, nextslide, direction) {
    // console.log(direction);
    if (direction === 'next') {
      TweenMax.set(obj.self.find('.stripe > div:eq(' + (nextslide - 1) + ') .background-image'),
        { right: '0%', left: '0%' }
      );
      TweenMax.to(obj.self.find('.stripe > div:eq(' + (nextslide - 1) + ') .background-image'), .5, 
        { right: '0%', left: '100%' }
      );

      TweenMax.set(obj.self.find('.stripe > div:eq(' + (nextslide) + ') .background-image'),
        { right: '100%', left: '0%' }
      );
      TweenMax.to(obj.self.find('.stripe > div:eq(' + (nextslide) + ') .background-image'), .5, 
        { right: '0%', left: '0%' }
      );
    } else if (direction === 'prev') {
      TweenMax.set(obj.self.find('.stripe > div:eq(' + (nextslide) + ') .background-image'),
        { right: '0%', left: '100%' }
      );
      TweenMax.to(obj.self.find('.stripe > div:eq(' + (nextslide) + ') .background-image'), .5, 
        { right: '0%', left: '0%' }
      );

      TweenMax.set(obj.self.find('.stripe > div:eq(' + (nextslide + 1) + ') .background-image'),
        { right: '0%', left: '0%' }
      );
      TweenMax.to(obj.self.find('.stripe > div:eq(' + (nextslide + 1) + ') .background-image'), .5, 
        { right: '100%', left: '0%' }
      );
    } else {
      TweenMax.set(obj.self.find('.stripe > div .background-image'),
        { right: '0%', left: '0%' }
      );
    }
  }

  initSliders(runThis, itemsPerSlide) {
    var self = this;
    //$('.slider .image').fitVids();
    // sliders = $('.slider:not(.started)');
    // alert(itemsPerSlide);
    runThis.each(function() {
      var slider;
      slider = {
        self: $(this),
        nav: $(this).find('.slider-navigation'),
        nextbutton: $(this).find('.right'),
        prevbutton: $(this).find('.left'),
        interval: 0,
        itemsPerSlide: itemsPerSlide
      };

      //slider.mc.destroy();
      slider.mc = new Hammer.Manager(slider.self[0]);
      slider.mc.add(new Hammer.Swipe());

      slider.self.find('.stripe').css({
        width: (slider.self.find('.stripe.crow .slide').length) * (100 / itemsPerSlide) + '%'
      });
      slider.self.css({
        opacity: 1
      });

      if (slider.self.find('.stripe.crow .slide').length <= slider.itemsPerSlide) {
        slider.nextbutton.addClass('inactive');
      }

      if (slider.self.find('.stripe.crow .slide').length > 1) {
        if (typeof slider.self.data('currentslide') === 'undefined') {
          slider.self.data('currentslide', 0);
        }
        if (slider.interval) {
          slider.self.data('interval', setInterval(function() {
            self.slideTo('next', slider, true);
          }, slider.interval));
        }
      }
      if (slider.nextbutton.length) {

        if (Utils.getDevice() === 'smartphone' && slider.self.find('.stripe.crow .slide').length > 3) {
          slider.mc.on('swipeleft', function() {
            self.slideTo('next', slider);
          });
        }

        slider.nextbutton.unbind('click');
        slider.nextbutton.on('click', function() {
          self.slideTo('next', slider);
          return false;
        });
      }
      if (slider.prevbutton.length) {

        if (Utils.getDevice() === 'smartphone' && slider.self.find('.stripe.crow .slide').length > 3) {
          slider.mc.on('swiperight', function() {
            self.slideTo('prev', slider);
          });
        }

        slider.prevbutton.unbind('click');
        slider.prevbutton.on('click', function() {
          self.slideTo('prev', slider);
          return false;
        });
      }

      slider.nav.find('a').unbind('click');
      slider.nav.find('a').on('click', function() {
        clearInterval(slider.self.data('interval'));

        let index = slider.nav.find('a').index(this);
        self.slide(slider, index, slider.self.data('currentslide'));
        return false;
      });
    });
  }

}