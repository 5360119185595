import $ from 'jquery';

import Utils from '../global/Utils';
import Api from '../global/Api';
import Slider from '../global/Slider';

let current = '';

export default class ProductCarousel	{
	constructor(o) {
		this.obj = $(o);
		this.el = $(o).get();
		this.addListeners();
		this.init();
	}

	init() {
		var self = this;

		$(document).on('windowResize', function(e, vals) {
			if (vals.device === 'smartphone') {
				new Slider(self.obj.find('.slider'), 3);
			} else {
				new Slider(self.obj.find('.slider'), 5);
			}
		});

		let stripe = $('.stripe');
		let stripeCount = stripe.find('.slide').length;
		if (stripeCount >= 5) {
			stripe.find('.slide:eq(' + 2 + ') a').addClass('active');
		}
		if (stripeCount < 5 && stripeCount > 1) {
			stripe.find('.slide:eq(' + 1 + ') a').addClass('active');	
		}
		if (stripeCount === 1) {
			stripe.find('.slide:eq(' + 0 + ') a').addClass('active');	
		}

		stripe.find('.slide a').on('mouseover', function() {
			$(this).parents('.stripe').find('a').removeClass('active');
			$(this).addClass('active');
		});
	}

	addListeners() {
		/*this.obj.find('li').on('click', e => {
			window.location.href = '#' + $(e.currentTarget).attr('data-product');
		});*/

	}
	
}