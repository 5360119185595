import $ from 'jquery';

export default class RecipeDock  {
  constructor(o) {
    this.obj = $(o);
    this.el = $(o).get();
    this.categoryCards = this.obj.find('.category-card');
    this.selected = false;
    this.sliders = $('.mod-slider');
    this.addListeners();

    let _target = this.obj.parents('.main-region');
    this.target = _target ? _target : null;

    this.selected = this.getSelectedFromUrl(window.location.href);

    this.init();
    this.displaySelectedRecipes();
  }

  init() {
    var self = this;

    $(document).on('windowResize', function(e, vals) {
      if (vals.device === 'smartphone') {
        self.obj.find('.category-list').css({ 'width' : (50*self.obj.find('.category-list li').length)+'%' });
      } else {
        self.obj.find('.category-list').css({ 'width' : '100%' });
      }
    });
  }

  getSelectedFromUrl(url) {
     return url.indexOf('#') !== -1 ? url.split('#').pop() : '';
  }

  addListeners() {
    var self = this;

    this.categoryCards.on('click', function() {
      let obj = $(this);

      if (!obj.hasClass('active')) {
        self.pickCategory(obj);
        self.categoryCards.removeClass('active');
        obj.addClass('active');
      }
    });

    this.sliders.find('a.btn').on('click', function() {
      self.unsetCategory();
      return false;
    });

    window.addEventListener("hashchange", function() {
      self.selected = self.getSelectedFromUrl(window.location.href);
      self.displaySelectedRecipes();
    }, false);
  }

  unsetCategory() {
    this.categoryCards.removeClass('active');
    this.sliders.removeClass('search');
    this.selected = false;

    this.displaySelectedRecipes();
  }

  pickCategory(obj) {
    this.sliders.addClass('search');
    this.sliders.find('.category-headline h1').html(obj.attr('data-headline'));
    this.sliders.css({ 'background-image' : 'url(\''+obj.attr('data-image')+'\')' });
  }

  displaySelectedRecipes() {
    if (this.target) {
      if (this.selected) {
        this.target.find('.mod:not(.mod-slider):not(.mod-categorystripe):not(.mod-topimage)').addClass('hidden');
      } else {
        this.target.find('.mod:not(.mod-slider):not(.mod-categorystripe):not(.mod-topimage)').removeClass('hidden');
      }

      this.target.find('.mod-recipelist[data-product]').addClass('hidden');
      this.target.find('.mod-recipelist[data-product]').slideUp(300);
      this.target.find(`[data-product="${this.selected}"]`).slideDown(300);
    }
  }

}
