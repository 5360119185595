import 'babel-polyfill';
import './modules/VideoButton';
import $ from 'jquery';
import Utils from './global/Utils';
import ProductDock from './modules/ProductDock';
import ProductPage from './modules/ProductPage';
import ProductCarousel from './modules/ProductCarousel';
import RecipeIngredients from './modules/RecipeIngredients';
import RecipeDock from './modules/RecipeDock';
import BigSlider from './modules/BigSlider';
import RecipeSlider from './modules/RecipeSlider';
import RecipeList from './modules/RecipeList';
import ArticleSlider from './modules/ArticleSlider';
import TopBar from './modules/TopBar';
import Share from './global/Share';
import ArticlePush from './modules/ArticlePush';
import StoreSearch from './modules/StoreSearch';
import Article from './modules/Article';
import InstagramFeed from './modules/InstagramFeed';
import RecipeSearch from './modules/RecipeSearch';
import 'lazysizes';

class Main {
	constructor() {
		// Check if home page and run some custom functionality

		if ($('.page-snofrisk').length || $('.page-brunost').length || $('.page-jarlsberg').length || $('.page-tineasia').length ) {
			var self = this;
			$(document).on('windowResize', function(e, vals) {
				self.resizeFeaturedImage();
			});

			$('.go-down-indicator').on('click', function() {
				let obj = $(this);
				var headerHeight = 97;
				$('html, body').animate({
					scrollTop: obj.parents('.mod').next().offset().top - headerHeight
				}, 500);

				return false;
			});

			this.checkStartSlideShow();
		}

		this.findHooks();
		this.globalFunctionality();
		this.onWindowResize();
		this.onHashChange();
		this.onWindowScroll();
	}

	checkStartSlideShow() {
		let grabFrom = '';
		if ($('#hero-images img').length > 1) {
			setInterval(function() {
				grabFrom = $('#hero-images img.current').next();

				if (!grabFrom.length) {
					grabFrom = $('#hero-images img:eq(0)');
				}

				$('#hero-images img').removeClass('current');

				$('.mod-topimage').css({
					backgroundImage : `url(${grabFrom[0].currentSrc})`
				});
				grabFrom.addClass('current');
			}, 10000);
		}
	}

	globalFunctionality() {
		$(document).on('windowScroll', function(e, vals) {
			if (!vals.fromTop && !$('.top .fullwidth').hasClass('permanent')) {
			  $('.top .fullwidth').removeClass('sticky');
			} else {
				$('.top .fullwidth').addClass('sticky');
			}
		});
		$(window).on('hashchange', e => {
			this.onHashChange(e);
		});
	}

	resizeFeaturedImage() {
		let height = $(window).height();
		if (height < 700) {
			height = 700;
		}
		if (Utils.getDevice() === 'smartphone') {
			height = $(window).height();
		}
		$('.mod.mod-topimage').css({ height: height });
	}

	onHashChange() {
		if (window.location.hash) {
			let hash = window.location.hash.substring(window.location.hash.indexOf('#') + 1);
			// console.log(hash);
      if ($(`[data-scroll-me="${hash}"]`).length) {
      	setTimeout(function() {
	      	$('html, body').animate({
	      		scrollTop: $(`[data-scroll-me="${hash}"]`).offset().top
	      	}, 750);
	      }, 500);
      }
		}
	}

	onWindowScroll() {
		$(document).trigger('windowScroll', {
			fromTop: $(window).scrollTop()
		});

		$(window).on('scroll', function(e) {
			$(document).trigger('windowScroll', {
				fromTop: $(window).scrollTop()
			});
		});
	}

	onWindowResize() {
		// Trigger "windowResize" once when possible
		$(document).trigger('windowResize', {
			device: Utils.getDevice(),
			orientation: Utils.getOrientation()
		});

		// Trigger "windowResize" when window is resized
		if (Utils.getDevice() !== 'smartphone') {
			$(window).resize(function() {
				clearTimeout(window.globalCheck);
				window.globalCheck = setTimeout(function() {
					$(document).trigger('windowResize', {
						device: Utils.getDevice(),
						orientation: Utils.getOrientation()
					});
				}, 500);
			});
		}
	}

	findHooks() {
		// console.log('find hooks');
		$('[data-hook]').each((i, o) => {
			this.addHook(o);
		});
	}

	addHook(o) {
		let hook = $(o).attr('data-hook');
		// console.log('found hook', hook);
		switch (hook) {
			case 'product-dock':
				new ProductDock(o);
				break;
			case 'product-page':
				new ProductPage(o);
				break;
			case 'product-carousel':
				new ProductCarousel(o);
				break;
			case 'recipe-ingredients':
				new RecipeIngredients(o);
				new Share(o);
				break;
			case 'top-bar':
				new TopBar(o);
				break;
			case 'store-search':
				new StoreSearch(o);
				break;
			case 'big-slider':
				new BigSlider(o);
				break;
			case 'recipe-slider':
				new RecipeSlider(o);
				break;
			case 'article-slider':
				new ArticleSlider(o);
				break;
			case 'recipe-dock':
				new RecipeDock(o);
				new RecipeSearch();
				break;
			case 'recipe-list':
				new RecipeList(o);
				break;
			case 'share':
				new Share(o);
				break;
			case 'google-map':
				return initMap(o);
			case 'article-push':
				new ArticlePush(o);
				break;
			case 'article':
				new Article(o);
				break;
			case 'instagram-feed':
				new InstagramFeed(o);
				break;
		}
	}
}

const initMap = async (o) => {
	const GoogleMap = await import('./modules/GoogleMap');
	new GoogleMap.default(o);
};

const _main = new Main;
