const searchContainerSelector = 'div.recipe-search-results';
const recipeNameSelector = '.mod-recipelist a.recipe-box > div.text';
const searchBoxSelector = '.recipe-search-box';
const noHitsSelector = '.recipe-search-no-hits';
const categoryContainerSelector = 'div.mod-recipelist';

const showClassname = 'show';
const hiddenClassname = 'hidden';

class IndexedElement {
    /** @type {HTMLElement} The outmost element of the recipe list-element. */
    element;
    /** @type {string} The name of the recipe. */
    text;
    /** @ype string The name of the recipe-category. */
    category;

    /**
     * @param {HTMLElement|Element} textContainer
     */
    constructor(textContainer) {
        this.element = textContainer.closest('.ws-4');
        this.text = textContainer.innerText.toLowerCase();
        this.category = textContainer.closest(categoryContainerSelector).dataset.product;
    }
}

export default class RecipeSearch {
    /** @type {HTMLElement} Container for the results. */
    searchContainer;
    /** @type {IndexedElement[]} All recipes available. */
    searchIndex = [];
    /** @type {HTMLElement} Displayed if user gets no search-hits. */
    noHitsElement;

    constructor() {
        document.addEventListener('DOMContentLoaded', this.onDocumentReady);
    }

    showSearchContainer = () => {
        this.searchContainer.classList.remove(hiddenClassname);
        this.searchContainer.style.opacity = '1';
    };

    hideSearchContainer = () => {
        this.searchContainer.style.opacity = '0';
        this.searchContainer.classList.add(hiddenClassname);
    };

    onDocumentReady = () => {
        // Grab all recipes from the dom and add them to the index.
        const recipes = document.querySelectorAll(recipeNameSelector);

        const dedupedSearchIndex = {};
        recipes.forEach((textContainer) => dedupedSearchIndex[textContainer.innerText] = textContainer);
        // Add the unique elements to the search-index.
        this.searchIndex = Object
            .values(dedupedSearchIndex)
            .map((textContainer) => new IndexedElement(textContainer));

        this.searchContainer = document.querySelector(searchContainerSelector);
        this.noHitsElement = document.querySelector(noHitsSelector);

        document.querySelector(searchBoxSelector).addEventListener('input', this.showResults);

        this.hideSearchContainer();
    };

    /**
     * Handle differences between the two sites.
     */
    getFallbackCategory = () => {
        const fallback = document.querySelector('.mod-recipelist.dark');
        return fallback ? fallback : document.querySelector('.mod-recipelist.bright');
    };

    /**
     * @return {HTMLElement} The current category selected
     */
    getCurrentCategory = () => {
        const currentCategoryName = window.location.hash.substring(1);
        const currentCategory = document.querySelector(`div[data-product="${currentCategoryName}"]`);
        // Return current category, or fallback to nameless main-category.
        return currentCategory ? currentCategory : this.getFallbackCategory();
    };

    showResults = (event) => {
        const searchText = event.target.value.toLowerCase();
        // User cleared the search, reset the state of the dom.
        if (searchText.length === 0) {
            this.resetSearch();
            return;
        }

        const {searchIndex, noHitsElement, searchContainer} = this;

        const searchResultContainer = searchContainer.querySelector('.crow');
        // Remove previous results.
        searchResultContainer
            .querySelectorAll('.ws-4')
            .forEach((result) => result.remove());

        const hits = searchIndex.filter(({text}) => text.indexOf(searchText) !== -1);
        // Add the results to the container.
        hits.forEach(({element}) => {
            const result = element.cloneNode(true);
            result.classList.add(showClassname);
            result.style.display = 'inline-block';

            searchResultContainer.append(result);
        });

        // Hide current category.
        this.getCurrentCategory().style.display = 'none';
        // Show the results.
        this.showSearchContainer();

        // Display/hide no-hits-text depending on the search-results.
        if (hits.length === 0) {
            noHitsElement.classList.remove(hiddenClassname);
        }
        else {
            noHitsElement.classList.add(hiddenClassname);
        }
    };

    resetSearch = () => {
        // Bring back the current category.
        this.getCurrentCategory().style.display = 'block';
        // Hide the search-results.
        this.hideSearchContainer();
    };
}
