import $ from 'jquery';

export default class Share	{
	constructor(o) {
		this.obj = $(o);
		this.el = $(o).get();
		this.addListeners();
	}

	addListeners() {
		this.obj.find('.facebook').off('click').on('click', e => this.fbShare(e));
		this.obj.find('.pinterest').off('click').on('click', e => this.pinterestShare(e));
		// console.log('share');
	}

	fbShare(e) {
		e.preventDefault();
		window.open('http://www.facebook.com/sharer.php?u=' + window.location.href);
	}

	pinterestShare(e) {
		e.preventDefault();
		window.open('http://pinterest.com/pin/create/button/?url=' + $(e.currentTarget).attr('data-url'));
	}
}