import $ from 'jquery';

import Utils from '../global/Utils';
import Slider from '../global/Slider';

let current = '';

export default class InstagramFeed {
  constructor(o) {
    this.obj = $(o);
    this.el = $(o).get();
    this.last = null;
    this.addListeners();
    this.init();
    this.numItems = this.obj.attr('data-num') || 8;
  }

  init() {
    let items = this.obj.find('.item');
    this.last = $(items[items.length - 1]).attr('data-date');
  }

  addListeners() {
    this.obj.find('.load-more > a').on('click', e => {
      e.preventDefault();
      this.loadMore();
    });
  }

  loadMore() {
    $.ajax({
      url: `http://tine-sparkle.azurewebsites.net/feed/10707?Count=${this.numItems}&CreatedBefore=${this.last}`,
      method: 'GET',
      success: result => this.populate(result)
    });
  }

  populate(result) {
    result.items.forEach(o => {
      this.obj.find('.items').append(this.element(o));
    });
    this.last = result.items[result.items.length - 1].createdTime;
    if (result.moreItems === false) {
      this.obj.find('.load-more').remove();
    }
  }

  element(o) {
    return `<div class="item crow" data-date="${o.createdTime}" >
        <a href="${o.url}" target="_blank" class="no-gutter recipe-box">
          <div class="image">
            <img src="${o.mediaUrl}" alt="" />
          </div>
          <div class="text">
            <b>${o.username}</b> <span>${o.text.substr(0, 95)}...</span>
          </div>
        </a>
      </div>`;
  }
  
}