import $ from 'jquery';

import Utils from '../global/Utils';
import Api from '../global/Api';
import Slider from '../global/Slider';

let current = '';

export default class BigSlider  {
  constructor(o) {
    this.obj = $(o);
    this.el = $(o).get();
    this.addListeners();
    this.init();
  }

  init() {
    new Slider(this.obj.find('.slider'), 1);
  }

  addListeners() {
    /*this.obj.find('li').on('click', e => {
      window.location.href = '#' + $(e.currentTarget).attr('data-product');
    });*/

  }
  
}