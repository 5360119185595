import MB from 'mapbox-gl/dist/mapbox-gl';
import $ from 'jquery';
import Api from '../global/Api';
import 'mapbox-gl/dist/mapbox-gl.css';

export default class GoogleMap {
  constructor(o) {
    this.obj = $(o);

    this.activeNode = false; // Will be an object later when real data is fetched, need to know datastructure first
    MB.accessToken = 'pk.eyJ1IjoiYnJ1bm9zdCIsImEiOiJjaXl5YnVlN2IwMDBhMzJvY3BjcGRteXc1In0.FcOc1knL3BWL_qOyTV5_6Q';

    this.map = new MB.Map({
      container: 'map',
      style: 'mapbox://styles/brunost/ciz8byi91004v2sntudlfzll4',
      zoom: 2,
      center: [-37.8,53],
      hash: true
    });

    // disable map rotation using right click + drag
    this.map.on('load', () => {
      this.fetchLocations();
      this.map.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      this.map.touchZoomRotate.disableRotation();
      this.addEventListeners();
      this.setPermanentSticky();
    });
    this.obj.find('input').focus();
  }

  setPermanentSticky() {
    $('.top .fullwidth').addClass('sticky permanent');
  }

  addEventListeners() {
    this.map.on('moveend', () => {
      if (this.activeNode) {
        this.map.zoomTo(17);
        $('div[data-id="'+this.activeNode+'"]').addClass('open');
        this.activeNode = false;
      }
    });

    let self = this;

    this.obj.find('.zoom-in').on('click', function() {
      self.map.zoomIn();
      return false;
    });
    this.obj.find('.zoom-out').on('click', function() {
      self.map.zoomOut();
      return false;
    });

    this.obj.find('.map-toggle').on('click', function() {
      let obj = $(this);
      if (obj.hasClass('active')) {
        self.hideSearch();
      } else {
        self.showSearch();
      }

      return false;
    });

    this.obj.find('input').on('keyup', function() {
      let obj = $(this);
      if (obj.val() !== '') {
        self.obj.find('.search-box').addClass('searching');
        self.makeSearch(obj.val());
      } else {
        self.obj.find('.search-box').removeClass('searching');
      }
    });

    this.obj.find('.magnifier').on('click', function() {
      self.makeRawSearch(self.obj.find('input').val());
      return false;
    });
    this.obj.find('form').on('submit', function() {
      self.makeRawSearch(self.obj.find('input').val());
      return false;
    });

    $(document).on('click', '.search-results li a', function() {
      let obj = $(this);

      self.activeNode = obj.attr('data-id');
      self.map.panTo([obj.attr('data-lng'), obj.attr('data-lat')]);
      self.hideSearch();

      return false;
    });
  }

  makeRawSearch(searchWords) {
    let self = this;
    let startString = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
    $.ajax({
      dataType: 'json',
      url: startString+(searchWords.toLowerCase())+'.json?access_token='+MB.accessToken,
      success: function(data) {
        if (data.features) {
          if (typeof data.features[0] !== 'undefined') {
            if (typeof data.features[0].bbox !== 'undefined') {
              let bbox = data.features[0].bbox;

              self.map.fitBounds([
                [bbox[0], bbox[1]],
                [bbox[2], bbox[3]]
              ]);
            } else {
              let lnglat = data.features[0].geometry.coordinates;
              self.activeNode = true;
              self.map.panTo([lnglat[0], lnglat[1]]);
            }

            self.hideSearch();
          }
        }
      }
    });
  }

  makeSearch(searchWords) {
    let html = '';
    let matches = this.searchArray(this.locations.features, searchWords);

    if (matches.length) {
      matches.forEach(match => {
        html += '<li>';
        html += '<a href="#" class="result"';
        html += 'data-lat="'+match.geometry.coordinates[1]+'"';
        html += 'data-lng="'+match.geometry.coordinates[0]+'"';
        html += 'data-id="'+match['_id']+'"';
        html += '><span>'+match.properties['address']+'</span>';
        html += '</a>';
        html += '</li>';
      });
    } else {
      html += '<li><span class="span"><strong>No stores found for that search term</strong><br />';
      html += ' You can browse specific places by pressing <i>enter</i> or clicking the magnifying glass.</span></li>';
    }

    this.obj.find('.search-results').html(html);
  }

  hideSearch() {
    this.obj.find('input').val('');
    this.obj.find('.map-toggle').removeClass('active');
    this.obj.find('.search-box').removeClass('active');
    this.obj.find('.search-box').removeClass('searching');
    this.obj.find('.mapboxgl-marker').removeClass('open');
  }

  showSearch() {
    this.obj.find('.map-toggle').addClass('active');
    this.obj.find('.search-box').addClass('active');
    this.obj.find('input').focus();
  }

  fetchLocations() {
    $.ajax({
      dataType: 'json',
      url: Api.getStoreFinderPath(),
      success: this.onLocationsFetched
    });
  }

  onLocationsFetched = (data) => {
    const features = Object
        .values(data)
        .map(this.formatLocationFeature);

    this.locations = {
      type: 'FeatureCollection',
      features: features,
    };

    this.generatePins();
  };

  formatLocationFeature = (location) => ({
    _id: location._id,
    type: 'Feature',
    properties: {
      displayName: location.displayName,
      'place_id': location.place_id,
      name: location._name,
      address: location.address
    },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(location.lng), parseFloat(location.lat)]
    }
  });

  generatePins() {
    this.map.addSource('stores', {
      type: 'geojson',
      data: this.locations,
      cluster: true,
      clusterMaxZoom: 18, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });

    this.map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'stores',
      filter: ['has', 'point_count'],
      paint: {
          'circle-color': {
              property: 'point_count',
              type: 'interval',
              stops: [
                  [0, '#96daf1'],
                  [6, '#64c1ef'],
                  [11, '#41a7e8'],
                  [21, '#1579c9'],
                  [41, '#1b62a7'],
                  [61, '#0f305d']
              ]
          },
          'circle-radius': {
              property: 'point_count',
              type: 'interval',
              stops: [
                  [0, 20],
                  [6, 25],
                  [11, 30],
                  [21, 35],
                  [41, 40],
                  [61, 45]
              ]
          }
      }
    });

    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'stores',
      filter: ['has', 'point_count'],
      layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 16
      },
      paint: {
        'text-color': '#ffffff'
      }
    });

    this.map.loadImage(
      window.markerIcon + '-inactive.png',
      (error, image) => {
        if (error) {
          throw error;
        }
        this.map.addImage('pin', image);

        this.map.addLayer({
          id: 'markers',
          type: 'symbol',
          source: 'stores',
          filter: ['!has', 'point_count'],
          layout: {
            'icon-image': 'pin',
            'icon-size': 0.75,
            'icon-anchor': 'bottom'
          }
        });
      });

      this.map.on('click', 'clusters', e => {
        this.hideSearch();
        this.map.flyTo({ center: e.lngLat, zoom: this.map.getZoom() + 2 });
      });

      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = '';
      });

      this.map.on('click', 'markers', e => {
        const loc = e.features[0];
        const coords = [loc.geometry.coordinates[0], loc.geometry.coordinates[1]];
        let html = '';
        html += `<svg version="1.1" id="box-shape" x="0px" y="0px" viewBox="0 0 200 277.5" style="enable-background:new 0 0 200 277.5;">
          <path class="st0" d="M195,0H5C2.2,0,0,2.2,0,5v247c0,2.8,2.2,5,5,5h78.5c8.4,4.5,12.2,20.5,16.6,20.5c0,0,0,0,0,0s0,0,0,0
            c4.4,0,8.2-15.9,16.6-20.5H195c2.8,0,5-2.2,5-5V5C200,2.2,197.8,0,195,0z"/>
          </svg>`;

        html += `<div class="marker-cross crow no-gutter">
                  <span class="icon icon-cross"></span>
                </div>`;
        html += '<div class="marker-place">'+loc.properties.displayName+'</div>';

        let address = loc.properties.address;
        address = address.replace(',', '<br />');
        html += '<div class="marker-address">'+address+'</div>';
        html += '<hr />';
        /* eslint max-len: 0 */
        html += '<a href="https://www.google.com/maps?saddr='+loc.geometry.coordinates[1]+','+loc.geometry.coordinates[0]+'"';
        html += ' class="marker-directions crow no-gutter" target="_blank">';
        html += '<span>Get directions</span> <span class="icon icon-bent-arrow"></span>';
        html += '</a>';
        this.hideSearch();
        this.map.panTo(coords, { offset: [ 0, 100] });
        let pu = new MB.Popup({
          anchor: 'bottom',
          offset: {
            bottom: [0, 0]
          },
          closeButton: false,
          closeOnClick: true
        })
          .setLngLat(coords)
          .setHTML(html)
          .addTo(this.map);

        $('.marker-cross').on('click', e => {
          pu.remove();
        });
      });

      this.map.on('mouseenter', 'markers', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'markers', () => {
        this.map.getCanvas().style.cursor = '';
      });
  }

  searchArray(arr, toSearch = '') {
    var newKey = '';
    var results = [];
    Object.keys(arr).forEach(key => {
      for (newKey in arr[key].properties) {
        if (typeof arr[key].properties[newKey] !== 'object') {
          // Search each array value on current key for a match
          if ((arr[key].properties[newKey]).toLowerCase().indexOf(toSearch.toLowerCase())!==-1) {
            // Make sure of no duplicates
            if (results.indexOf(arr[key]['_id'])===-1) {
              results.push(arr[key]);
            }
          }
        }
      }
    });

    if (results.length > 5) {
      results = results.slice(0, 5);
    }

    return results;
  }
}
