import $ from 'jquery';

import Utils from '../global/Utils';
import Api from '../global/Api';

const google = window.google;

export default class StoreSearch  {
	constructor(o) {
		// console.log('add hook places');
		this.obj = $(o);
		this.el = $(o).get();
		this.addListeners();
		this.init();
		this.data = {};
	}

	init() {
		this.map = new google.maps.Map(document.getElementById('map'));

	}

	addListeners() {
		this.obj.find('.button.search').on('click', e => this.onSearchClick(e));
		this.obj.find('.button.save').on('click', e => this.onSaveClick(e));
	}

	onSearchClick(e) {
		e.preventDefault();
		this.data._id = this.obj.find('input[name="_id"]').val();
		this.findPlace(this.obj.find('input#store-name').val());
		// console.log(this.data);
	}

	findPlace(str) {
		let service = new google.maps.places.PlacesService(this.map);
		service.textSearch({ query: str }, result => this.populateDetails(result));
	}

	populateDetails(result) {
		let details = {
			lat: result[0].geometry.location.lat(),
			lng: result[0].geometry.location.lng(),
			address: result[0].formatted_address,
			'place_id': result[0].place_id
		};

		for (let x in details) {
			this.data[x] = details[x];
			this.obj.find('.details .field').removeClass('empty');
			this.obj.find('.details input[name="' + x + '"]').val(details[x]);
		}
	}

	onSaveClick(e) {
		let obj = {
			_id: this.obj.find('input[name="_id"]').val(),
			data: {}
		};
		this.obj.find('.details input').each((i, o) => {
			obj.data[$(o).attr('id')] = $(o).val();
		});
		Api.postInternal(window.location.href, obj);
			/* .then(result => {
				console.log('result', result);
			})
			.catch(error => {
				console.error('error', error);
			}); */
	}

}