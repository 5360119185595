(() => {
	const playButtons = document.getElementsByClassName('video-button--play');
	if (playButtons) {
		Array.from(playButtons).forEach((playButton) => {
			const container = playButton.parentElement;
			const youtubeLink = playButton.dataset.youtubelink;
			const youtubeTitle = playButton.dataset.youtubetitle;
			playButton.addEventListener('click', () => {
				container.innerHTML = '';
				const videoPlayer = document.createElement('div');
				videoPlayer.setAttribute('class', 'youtube-video');
				const iFrame = document.createElement('IFRAME');
				iFrame.setAttribute('class', 'youtube-video__iframe');
				iFrame.setAttribute(
					'src',
					`https://www.youtube.com/embed/${youtubeLink}?autohide=0&color=white&controls=1&modestbranding=1&showinfo=0&rel=0&autoplay=1`
				);
				iFrame.setAttribute('frameBorder', '0');
				iFrame.setAttribute('allowfullscreen', '1');
				iFrame.setAttribute('title', youtubeTitle);
				videoPlayer.appendChild(iFrame);
				container.appendChild(videoPlayer);
			});
		});
	}
})();
