export default class Utils	{
	static cleanHash(str) {
		return str.replace(/[#!\/]/g, '');
	}
  static getOrientation() {
    let orientation = window.getComputedStyle(document.body, ':before');
    orientation = orientation.getPropertyValue('content').replace(/'/g, '').replace(/"/g, '');
    
    orientation = orientation.split(' ');
    orientation = orientation[1];
    
    return orientation;
  }
  static getDevice() {
    let device = window.getComputedStyle(document.body, ':before');
    device = device.getPropertyValue('content').replace(/'/g, '').replace(/"/g, '');
    
    device = device.split(' ');
    device = device[0];
    
    return device;
  }
}