import $ from 'jquery';
import Slider from '../global/Slider';

export default class ProductDock	{
	constructor(o) {
		this.obj = $(o);
		this.el = $(o).get();
		this.addListeners();
		this.selected = false;

		let _target = this.obj.parents('.main-region');
		this.target = _target ? _target : null;

		this.init();
	}

	init() {
		var self = this;

		$(document).on('windowResize', function(e, vals) {
			if (vals.device === 'smartphone') {
				new Slider(self.obj.find('.slider'), 3);
			} else {
				new Slider(self.obj.find('.slider'), 5);
			}
		});

		let stripe = this.obj.find('.stripe');
		let stripeCount = stripe.find('.slide').length;
		// console.log(stripeCount);
		if (stripeCount >= 5) {
			this.selected = stripe.find('.slide:eq(' + 2 + ') a').attr('data-product');
		}
		if (stripeCount < 5 && stripeCount > 1) {
			this.selected = stripe.find('.slide:eq(' + 1 + ') a').attr('data-product');
		}
		if (stripeCount === 1) {
			this.selected = stripe.find('.slide:eq(' + 0 + ') a').attr('data-product');
		}
		if (window.location.hash) {
      let hash = window.location.hash.substring(1); 

      let match = this.obj.find(`[data-product="${hash}"]`).parent();
      if (match.length) {
      	this.selected = hash;
      	match.prependTo(match.parents('.stripe'));

      	// Specificly for smartphone, reassign current classes
      	match.children('a').removeClass('next current previous').addClass('previous');
      	match.next().children('a').removeClass('next current previous').addClass('current');
      	match.next().next().children('a').removeClass('next current previous').addClass('next');
      }
    }

		this.displaySelectedRecipes();

	}

	addListeners() {
		this.obj.find('.slide').on('click', e => {
			this.onMouseClick(e);

			return false;
		});
		/*this.obj.find('li').on('mouseleave', e => {
			this.onMouseLeave(e);
		});*/
	}

	onMouseClick(e) {
		this.selected = $(e.currentTarget).attr('data-product');
		//window.location.hash = this.selected;
		window.history.pushState('shareablePage', 'Products', '#' + this.selected);
		this.displaySelectedRecipes();
	}

	displaySelectedRecipes() {
		let featuredImage = '';
		let clickBox = this.obj.find(`[data-product="${this.selected}"] a`);
		this.obj.find('a').removeClass('active');
		clickBox.addClass('active');

		featuredImage = clickBox.attr('data-featured-image');
		if (this.obj.hasClass('imagery')) {
			this.obj.find('.headline').html(this.obj.find(`[data-product="${this.selected}"] p`).html());
			if (featuredImage) {
				this.obj.find('.shadow-bg').css({ 'background-image' : 'url('+featuredImage+')' });
			}
		}
		
		if (this.target) {
			this.target.find('.mod-recipelist, .mod-product').addClass('hidden');
			this.target.find(`[data-product="${this.selected}"]`).removeClass('hidden');
		}

		this.obj.find('.headline').removeAttr('style');
	}

	/*onMouseLeave(e) {
		$(e.currentTarget).removeClass('active');
	}*/
}