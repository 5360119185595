import $ from 'jquery';

import Utils from '../global/Utils';
import Api from '../global/Api';
import Slider from '../global/Slider';

let current = '';

export default class ArticleSlider  {
  constructor(o) {
    this.obj = $(o);
    this.el = $(o).get();
    this.addListeners();
    this.init();
  }

  init() {
    var self = this;

    $(document).on('windowResize', function(e, vals) {
      if (vals.device === 'smartphone') {
        new Slider(self.obj.find('.slider'), 2);
      } else {
        new Slider(self.obj.find('.slider'), 4);
      }
    });
  }

  addListeners() {
    /*this.obj.find('li').on('click', e => {
      window.location.href = '#' + $(e.currentTarget).attr('data-product');
    });*/

  }
  
}