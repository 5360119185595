import $ from 'jquery';

import Utils from '../global/Utils';
import Api from '../global/Api';
import Slider from '../global/Slider';

let current = '';

export default class RecipeList  {
  constructor(o) {
    this.obj = $(o);
    this.el = $(o).get();
    this.addListeners();
    this.init();
  }

  init() {
    // Make sure there is at least 7 or more items for the show-more button to exist
    // NOTICE: The "show more"-class will only exist on recipes-page, otherwise the 
    // button itself will exist no matter what the recipe-count is
    if (this.obj.find('.crow > div').length < 7) {
      this.obj.find('.show-more').parent().slideUp(250);
    }

    this.obj.css({ 'opacity' : 1 });
  }

  addListeners() {
    var self = this;

    // When user clicks show-more, open up the rest of the recipes
    this.obj.find('.show-more').on('click', e => {
      self.obj.find('.show-more').parent().slideUp(250);
      self.obj.find('.crow > div').css({ 'display' : 'inline-block' });
      setTimeout(function() {
        self.obj.find('.crow > div').addClass('show');
      }, 50);

      return false;
    });

  }
  
}