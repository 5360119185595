import $ from 'jquery';

import Utils from '../global/Utils';
import Api from '../global/Api';

let current = '';

export default class ProductPage	{
	constructor(o) {
		this.obj = $(o);
		this.el = $(o).get();
		this.addListeners();
		this.hashChange();
		
	}

	addListeners() {
		window.onhashchange = () => {
			this.hashChange();
		};
	}

	hashChange() {
		current = Utils.cleanHash(window.location.hash) || 'naturell';
		this.displayProduct(current);
	}

	displayProduct(product) {
		// console.log($(`[data-product="${product}"]`));
		// $(`[data-product="${product}"]`).removeClass('hidden').siblings().addClass('hidden');
	}
	
}